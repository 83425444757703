/**
 * This file contains resource data used on the LibraryAware.com landing page
 */


export const featuredCards = [
	{
		title: 'NextReads newsletters',
		content: 'NextReads newsletters deliver reading recommendations directly to patron inboxes. They are the ideal solution for busy librarians because they save staff time and help build connections to readers. NextReads newsletters are included with a subscription to LibraryAware.',
		url: 'https://www.ebsco.com/novelist/products/libraryaware#sect2',
	},
	{
		title: 'Limitless possibilities',
		content: 'Unlike other newsletter solutions, LibraryAware offers more of what you need, with very few limitations. For example, you’ll get:',
		url: 'https://www.ebsco.com/novelist/products/libraryaware',
		contentListItems: ['Unlimited subscribers', 'Unlimited sends', 'Unlimited users', 'Unlimited image uploads'],
	},
	{
		title: "Readers' advisory templates",
		content: 'Create self-directed pathways that help readers discover books, whether they are wandering your stacks or browsing on their phone. LibraryAware includes hundreds of professionally designed templates for creating bibliographies, posters, reading maps, signs, bookmarks, and more.',
		url: 'https://www.ebsco.com/novelist/products/libraryaware#sect3',
	},
];

export const footerData = {
	copyrightText: 'EBSCO Information Services/NoveList',
	links: [
		{
			title: 'EBSCO Privacy Policy',
			url: 'https://www.ebsco.com/company/privacy-policy',
		},
		{
			title: 'GDPR',
			url: 'https://www.ebsco.com/gdpr',
		},
		{
			title: 'Support',
			url: 'https://libraryaware.uservoice.com/',
		},
	],
};

export const hyperlinks = {
	social: {
		facebook: "https://facebook.com/LibraryAware/",
		pinterest: "https://pinterest.com/libraryaware/"
	},
	learnMoreSection: {
		requestInfo:
			"https://www.ebsco.com/novelist/request-information",
		seeDemo: "https://www.ebsco.com/novelist/demos"
	},
	contactSection: {
		newsletter:
			"https://libraryaware.com/14/Subscribers/Subscribe?optInPageId=87f4958d-1511-448f-b8ad-42ded4895dd8",
		latestPost: "https://www.ebsco.com/blogs/novelist"
	}
};

export const sectionHeadings = {
	ExpandYourLibrarysReach: "Expand your library's reach",
	LearnMore: "Learn More",
	Social: "Let's get social",
	Contact: "Stay in the loop"
};
