// LibraryAware page title
export const LA_PAGE_TILE = "LibraryAware";
// Nav bar link labels
export const NAV_LINK_FEATURES = "Features";
export const NAV_LINK_SOCIAL = "Social";
export const NAV_LINK_NEWS = "News";
export const NAV_LINK_INFO = "Info";
export const NAV_LINK_HOME = "Home";
export const NAV_LINK_FOLDERS = "Folders";
export const NAV_LINK_NEWSLETTERS = "Newsletters";
export const NAV_LINK_SUBSCRIBERS = "Subscribers";
export const NAV_LINK_REPORTS = "Reports";
export const NAV_LINK_ADMIN = "Admin";
export const NAV_LINK_HELP = "Help";

// Branding set labels
export const BRANDING_LABEL_DEFAULT = "Default";
export const BRANDING_LABEL_SECONDARY = "Branding";
export const BRANDING_LABEL_CUSTOM = "Custom Brand";

// Document types
export const DOC_TYPE_FOLDERS = "folders";
export const DOC_TYPE_ITEMS = "items";

export const TEMPLATE = 'Template';
export const PROMOTION = 'Promotion';
export const HOME = 'Home';

// No content / error messages
// @TODO - organize these
export const LOADING = "Loading...";
export const NO_FOLDERS_MSG = "There are no folders.";
export const NO_ITEMS_RESP_ERR = "Search:Items Error";
export const NO_FOLDERS_RESP_ERR = "Promotions:GetPromotions Error:";
export const NO_DOCS_RESP_ERR = "Documents:GetDocuments Error:";
export const NO_PROMO_DETAILS_RESP_ERR = "Promotions:GetPromotion Error:";
export const NO_CREATE_FOLDER_RESP_ERROR = "CreateFolder Error:";
export const ARCHIVE_FOLDER_ERR = "Promotions:Archive Error:";
export const DELETE_FOLDER_ERR = "Promotions:Delete Error:";
export const UPDATE_FOLDER_DETAILS_RESP_ERR = "Promotions:EditFolder Error:";
export const NO_ITEMS_FOUND = "It's a ghost town around here. Click the Create Item button above and start your next masterpiece.";
export const DELETE_ITEM_ERR = "Documents:Delete Error:";
export const BULK_UNARCHIVE_ERR = "Promotions: Unarchive Error.";
export const TOGGLE_STAR_ERR = "Could not star/unstar item.";
export const NO_AVAILABLE_FILTERS_ERR = "Documents:GetAvailableFiltersForPromotionItems Error: ";
export const ERROR_UPLOADING_SUBSCRIBERS = "There was an error uploading the list of subscribers, please try again.";

export const ERROR_MSG = {
  EMAIL_EDITOR: {
    CONFIG_ERROR: "Could not initialize Email Editor configuration.",
    AUTH_TOKEN_ERROR: "Unable to obtain authentication token",
  },
};

/**
 * @todo - organize into an object
 */
// Folders section header labels
export const HEADER_TEXT_ALL_ITEMS = "All Items";
export const HEADER_TEXT_ALL_FOLDERS = "All Folders";
export const HEADER_TEXT_MY_FOLDERS = "My Folders";
export const HEADER_TEXT_RECENTLY_UPDATED_FOLDERS = "Recent";
export const HEADER_TEXT_STARRED_FOLDERS = "Starred";
export const HEADER_TEXT_ARCHIVED_FOLDERS = "Archived";
export const HEADER_TEXT_PAGE_NOT_FOUND = "Page Not Found";

/**
 * @todo - organize into an object
 */
// Folders section header descriptions
export const HEADER_DESCRIPTION_PAGE_NOT_FOUND = "Oops. We can't find the page you're looking for.";
export const HEADER_DESCRIPTION_ALL_ITEMS = "This view shows all the active Items in your organization.";
export const HEADER_DESCRIPTION_ALL_FOLDERS = "This view shows all the active folders in your organization.";
export const HEADER_DESCRIPTION_MY_FOLDERS = "This view shows all the active folders created by you.";
export const HEADER_DESCRIPTION_RECENTLY_UPDATED_FOLDERS = "This view shows all the active folders updated within the last 7 days.";
export const HEADER_DESCRIPTION_STARRED_FOLDERS = "View all Folders you have starred.";
export const HEADER_DESCRIPTION_ARCHIVED_FOLDERS = "View all Folders which have been archived.";

// Folder Table strings
export const TABLE_HEADERS = [
	// header text is blank for thumbnail col
	"",
	"Name",
	"Creator",
	"Creation Date",
	"Last Updated",
	"Items",
];

export const EDIT_FOLDER_FORM = {
	TITLE: "Folder Details",
	FOLDER_NAME_LABEL: "Folder Name",
	FOLDER_DESCRIPTION_LABEL: "Folder Description",
	BRANDING_SET_LABEL: "Choose a branding set",
	BRANDING_SET_HELPER:
		"The selected branding set will be automatically applied to items created in this folder.",
	CANCEL_BTN_TEXT: "Cancel",
	SAVE_BTN_TEXT: "Save Changes",
	NAME_FIELD_ERROR_MSG:
		"Woah there, partner. Around here, all the folder titles need to be between 1 and 100 characters. Sheriff's orders.",
	DESCRIPTION_FIELD_ERROR_MSG:
		"Woah there, partner. Around here, all the folder descriptions need to be between 1 and 1000 characters. Sheriff's orders.",
	// Create details
	CREATE_BTN_LABEL: "Create Folder",
	CREATE_SAVE_BTN_TEXT: "Create Folder",
};

export const ITEM_COPY_FORM = {
	TITLE: "Copy Item",
	BODY: "You are creating a copy of",
	FORM_CONTROLS: {
		TEXT_INPUTS: {
			ITEM_NAME: {
				LABEL: "New Item Name",
				ARIA: "Provide a new name for the item's copy",
				ERROR: "Woah there, partner. Around here, all the folder titles need to be between 1 and 100 characters. Sheriff's orders.",
			},
		},
		SELECT_INPUTS: {
			TARGET_FOLDER: {
				LABEL: "Copy to..",
				HELPER: "Can be any active folder in your organization",
				ARIA: "Make a selection of any active folder in your organization to copy this item to.",
			},
		},
		BUTTONS: {
			CANCEL: {
				LABEL: "Cancel",
				ARIA: "Cancels the copying of this item",
			},
			CONFIRM: {
				LABEL: "Copy Item",
				ARIA: "Copies the item to the target folder",
			},
		},
	},
};

// Confirmation Dialog Text
export const ARCHIVE_FOLDER_DIALOG_TEXT = "Archiving this folder will move it to your archived folders and it will no longer be visible in other views. You can unarchive a folder from the Archived Folders view.";
export const DELETE_FOLDER_DIALOG_TEXT = "Deleting this folder will permanently remove it from your organization and can't be undone.";

export const DELETE_FOLDER_DIALOG = {
	TITLE: "Delete Folder",
	BODY: DELETE_FOLDER_DIALOG_TEXT,
	DELETE_BTN: "DELETE FOLDER",
	CANCEL_BTN: "CANCEL",
};

export const ARCHIVE_FOLDER_DIALOG = {
	TITLE: "Archive Folder",
	BODY: ARCHIVE_FOLDER_DIALOG_TEXT,
	ARCHIVE_BTN: "ARCHIVE FOLDER",
	CANCEL_BTN: "CANCEL",
};

export const DELETE_ITEM_DIALOG = {
	TITLE: "Delete Item",
	BODY: "Deleting this item will remove all previous posts and cancel all future posts for this item. You can no longer edit or post this item, and any links to this item will no longer work.",
	DELETE_BTN: "DELETE ITEM",
	CANCEL_BTN: "CANCEL",
};
// URI resources
export const URI_RESOURCE = {
	getPromotions: "/Promotions/GetPromotions",
	getDocuments: "/Documents/GetDocumentsWithSortAndFilters",
	createFolder: "/Promotions/CreateFolder",
	getFolder: "/Promotions/GetPromotion",
	getActivePromotionsForOrg: "/Promotions/GetActivePromotionsForOrg",
	getArchivedPromotionsForOrg: "/Promotions/GetArchivedPromotionsForOrg",
	getActivePromotionsForAUser: "/Promotions/GetActivePromotionsForAUser",
	getActiveStarredPromotionsForAUser:
		"/Promotions/GetActiveStarredPromotionsForAUser",
	archiveFolder: "/Promotions/Archive",
	bulkUnarchive: "/Promotions/BulkUnarchive",
	deleteFolder: "/Promotions/Delete",
	updateFolder: "/Promotions/EditFolder",
	copyItem: "/Documents/Copy",
	deleteItem: "/Documents/Delete",
	getActiveItemsForOrg: "/Search/Items",
	getAvailableFiltersForFolderItems:
		"/Documents/GetAvailableFiltersForPromotionItems",
	toggleItemStar: "/UserStarredItem/ToggleUserStarred",
	getOrgTree: "/Organizations/NavTreeJson",
	getOrgName: "/Organizations/GetOrganizationName",
	getPublishTargets: "/Documents/GetPublishTargets",
	getNewsletterWidgetById: "/Widgets/GetNewsletterWidgetById",
	EMAIL_EDITOR: {
		authToken: "EmailEditor/AuthToken",
		AjaxImageUpload: "Files/AjaxImageUpload",
		createFolder: "EmailEditor/CreateFolder",
		displayImage: "Files/Display",
		getBookJacketItems: "Replaceables/CoverArtSearch",
		getCoverArt: "Replaceables/CoverArtSearch",
		getDocumentInfo: "EmailEditor/GetDocumentInfo",
		getBrandingSets: "EmailEditor/GetBrandingSets",
		getImageDialogOrgData: "Assets/ImageDialogJson",
		getBookEResourceInfo: "Replaceables/RecordSearch",
		doesOrgHaveEventsFeed: "Replaceables/EventsEnabled",
		getRssFeedReplaceables: 'Replaceables/GetRssFeedReplaceables',
		dataSourcesGetFeeds: "DataSources/GetFeeds",
		getCatalogLink: "Organizations/CatalogLink",
		getImageInfo: "EmailEditor/GetImageInfo",
		getAVCoverArtInfo: "EmailEditor/GetAVCoverArtInfo",
		getPrintView: "EmailEditor/FetchFinalizedHtml",
		getFoldersList: "Promotions/GetActivePromotionsForOrg",
		helpInfo: "https://libraryaware.uservoice.com",
		imageUrlUpload: 'Files/AjaxUrlUpload',
		listImageFiles: "Files/ListImageFiles",
		listItemThumbnails: "Documents/ListItemThumbnails",
		listNewsletterIssues: 'NewsletterIssues/List',
		listNewsletterLists: 'NewsletterLists/List',
		getPersistentLink: "Organizations/GeneratePersistentLink",
		thumbnailForDocuments: "Files/ThumbnailForDocument",
		uploadFile: "Files/AjaxUpload",
		fromNewsletterIssue:"Replaceables/FromNewsletterIssue",
		DOCUMENT: {
			get: "EmailEditor/GetStripoDocument",
			releaseLock: "EmailEditor/ReleaseDocumentLock",
			renewLock: "EmailEditor/RenewDocumentLock",
			save: "EmailEditor/Save",
			saveAs: "EmailEditor/SaveAs"
		}
	},
	ON_THE_SHELF: {
		AVAILABILITY: "OnTheShelf/Availability",
		AVAILABILITY_WITH_OTS_CHECK: "OnTheShelf/AvailabilityWithOtsCheck",
		AVAILABILITY_WITH_NEH:"OnTheShelf/NehAvailability",
		OTS_ENABLED: "OnTheShelf/IsOtsEnabled"
	}
};

// Theme resources
export const GOOGLE_FONT_URL = "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Open+Sans:300,400,500,700&display=swap";

// Route resources
export const ROUTE_URIS = {
	FOLDERS_ALL_ITEMS: "#!/folders/items",
	FOLDERS_ALL_FOLDERS: "#!/folders",
	FOLDERS_MY_FOLDERS: "#!/folders/my-folders",
	FOLDERS_RECENTLY_UPDATED_FOLDERS: "#!/folders/recently-updated",
	FOLDERS_STARRED_FOLDERS: "#!/folders/starred-folders",
	FOLDERS_ARCHIVED_FOLDERS: "#!/folders/archived-folders",
	LOGOUT: "/logout",
	PROFILE_SETTINGS: "#!/account/settings",
	SUPPORT: "#!/support",
	HOME: "#!/home",
	NEWSLETTERS: "#!/newsletters",
	SUBSCRIBERS: "#!/subscribers",
	REPORTS: "#!/reports",
	ADMIN: "#!/admin",
	ADMIN_FOR_PUBLISHERS: "#!/admin/templates",
	NEW: "https://libraryaware.uservoice.com/knowledgebase/articles/889290-status-of-libraryaware",
	HELP: "https://libraryaware.uservoice.com/"
};

// Navigation Bar Resources
export const SITE_CONTROLS = {
	PROFILE: {
		SETTINGS: {
			LABEL: "personal settings",
			ALT: "edit your personal settings",
		},
		SUPPORT_DASHBOARD: {
			LABEL: "support dashboard",
			ALT: "go to the support dashboard",
		},
	},
	LOGOUT: {
		LABEL: "logout",
		ALT: "log out of your account",
	},
	WHATS_NEW_TITLE: "What's new?",
	HELP_TITLE: "Help!"
};

// Item Card Menu Action Strings
export const ITEM_MENU_ACTIONS = {
	ADD_TO_CAROUSEL: "Add to Carousel",
	ADD_TO_WIDGET: "Add to Widget",
	COPY: "Copy Item",
	DELETE: "Delete",
	EDIT: "Edit",
	FACEBOOK: "Share on Facebook",
	INSTAGRAM: "Share on Instagram",
	PINTEREST: "Share on Pinterest",
	PRINT: "Print",
	SEND_EMAIL: "Send Email",
	SEND_TEST_EMAIL: "Send Test Email",
	VIEW_PDF: "View PDF",
	VIEW_IMG: "View Image",
};

export const FOLDER_SETTINGS_MENU = {
	LABEL: "Folder Settings",
	FOLDER_ARCHIVE: "Archive Folder",
	FOLDER_DELETE: "Delete Folder",
	FOLDER_EDIT: "Edit Folder Details",
	FOLDER_REPORT: "Folder Report",
	FOLDER_SCHEDULE: "Folder Schedule",
};

// Item Sort Dropdown Strings
export const ITEM_SORT_OPTIONS = {
	CREATION_DATE_DESC: "Created - Newest",
	CREATION_DATE_ASC: "Created - Oldest",
	MODIFIED_DATE_DESC: "Modified - Newest",
	MODIFIED_DATE_ASC: "Modified - Oldest",
	NAME_ASC: "Name - A to Z",
	NAME_DESC: "Name - Z to A",
};

// Button Strings
export const SHOW_FILTERS = "Show Filters";
export const STAR_FILTER = {
	ALL_ITEMS: "All Items",
	STARRED_ITEMS: "Starred Items"
}

// Snackbar Severity Levels
export const SNACKBAR_SEVERITY_LEVEL = {
	ERROR: "error",
	SUCCESS: "success"
}

export const SNACKBAR_MESSAGES = {
	ARCHIVE_FOLDER_SUCCESS: "Your folder has been archived.",
	ARCHIVE_FOLDER_ERROR: "We were unable to archive your folder. Please try archiving it again.",
	CREATE_FOLDER_SUCCESS: "Your folder has been created.",
	CREATE_FOLDER_ERROR: "We were unable to create your new folder. Please try creating it again.",
	COPY_ITEM_SUCCESS: "Your item has been copied",
	COPY_ITEM_ERROR: "We were unable to copy your item. Please try copying it again.",
	DELETE_FOLDER_SUCCESS: "Your folder has been deleted.",
	DELETE_FOLDER_ERROR: "We were unable to delete your folder. Please try deleting it again.",
	DELETE_ITEM_SUCCESS: "Your item has been deleted.",
	DELETE_ITEM_ERROR: "We were unable to delete your item. Please try deleting it again.",
	UPDATE_FOLDER_DETAILS_SUCCESS: "Your folder's details have been updated.",
	UPDATE_FOLDER_DETAILS_ERROR: "We were unable to update your folder's details. Please try again.",
	UNARCHIVE_FOLDER_SUCCESS: "Your folder has been unarchived.",
	UNARCHIVE_FOLDER_ERROR: "We were unable to unarchive your folder. Please try unarchiving it again."
}

export const NAV_MENU_CUSTOM_ICONS = {
	HELP: "https://d26ok4vql6q4e7.cloudfront.net/assets/libraryaware-help-icon.svg",
	INFO: "https://d26ok4vql6q4e7.cloudfront.net/assets/libraryaware-info-icon.svg",
}

// Element Ids
export const EMAIL_EDITOR_DIV_ID = "email-editor";
